import React from 'react';

const NotFound = () => {
    return (
        <>
            <div className="NotFound">
                <h1 style={{ fontSize: '72px', textAlign: 'center', color: '#15cdfc', marginTop: '25vh' }}>404</h1>
            </div>
        </>
    );
};

export default NotFound;