import React from 'react'

const mobilePentesting = () => {
    return (
        <div className="mobilePentesting" id='links'>
            <h1>Mobile Pentesting</h1>
            <br></br>
            <br></br>
            <div>
                <h2>Android</h2>
                <br></br>
                <ul>
                    <li><a href="https://medium.com/swlh/an-android-hacking-primer-3390fef4e6a0">How to get started hacking Android applications</a></li>
                    <li><a href="https://owasp.org/www-project-mobile-security-testing-guide/">Owasp Mobile Security Testing Guide</a></li>
                    <li><a href="https://3xpl01tc0d3r.blogspot.com/2019/09/security-testing-for-android-app-part1.html">Security Testing for Android Cross Platform App</a></li>
                    <li><a href="https://blog.0daylabs.com/2019/09/18/deep-dive-into-Android-security/">Deep Dive into Android Security</a></li>
                    <li><a href="https://notsosecure.com/pentesting-android-apps-using-frida/">Pentesting Anroid Apps using Frida</a></li>
                    <li><a href="https://mobile-security.gitbook.io/mobile-security-testing-guide/">Mobile Security Testing Guide</a></li>
                    <li><a href="https://github.com/tanprathan/MobileApp-Pentest-Cheatsheet">Mobile Application Pentesting Cheatsheet</a></li>
                    <li><a href="https://www.evilsocket.net/2017/04/27/Android-Applications-Reversing-101/#.WQND0G3TTOM.reddit">Android Applications Reversing 101</a></li>
                    <li><a href="https://developer.box.com/guides/security/">Android Security Guidlines</a></li>
                    <li><a href="https://source.android.com/security">How to Secure an Anroid Device</a></li>
                    <li><a href="https://pentestlab.blog/2017/02/12/android-webview-vulnerabilities/">Android WebView vulnerabilities</a></li>
                    <li><a href="https://owasp.org/www-project-mobile-top-10/">Owasp Mobile Top 10</a></li>
                    <li><a href="https://resources.infosecinstitute.com/topic/practical-android-phone-forensics/">Practical Android Phone Forensics</a></li>
                    <li><a href="https://github.com/quark-engine/quark-engine">An Obfuscation-Neglect Android Malware Scoring System</a></li>
                    <li><a href="https://hackernoon.com/hands-on-mobile-api-security-get-rid-of-client-secrets-a79f111b6844">Hands on Mobile API Security</a></li>
                    <li><a href="https://nileshsapariya.blogspot.com/2016/11/zero-to-hero-mobile-application-testing.html">Zero to Hero Mobile Pentesting</a></li>
                    <li><a href="https://docs.google.com/presentation/d/1pYB522E71hXrp4m3fL3E3fnAaOIboJKqpbyE5gSsOes/edit#slide=id.p1">DeepSec Android Malware Adventures</a></li>
                    <li><a href="https://github.com/B3nac/Android-Reports-and-Resources/blob/master/README.md">Android Reports and Resources</a></li>
                    <li><a href="https://github.com/ashishb/android-security-awesome">Android Security Awesome</a></li>
                    <li><a href="https://medium.com/mobile-penetration-testing/android-penetration-testing-courses-4effa36ac5ed">Anroid Pentesting Courses</a></li>
                    <li><a href="https://captmeelo.com/pentest/2019/12/30/lesser-known-tools-for-android-pentest.html">Lesser Known Tools for Android Pentesting</a></li>
                    <li><a href="https://github.com/nelenkov/android-device-check">A Set of Scripts to Check Android Device Security Configuration</a></li>
                    <li><a href="https://github.com/shroudedcode/apk-mitm">A CLI Application that Prepares Android APK files for HTTPS Inspection</a></li>
                    <li><a href="https://github.com/den4uk/andriller">Andriller - A Software Utility with a Collection of Forensic Tools for Smartphones</a></li>
                    <li><a href="https://www.virusbulletin.com/virusbulletin/2019/11/dexofuzzy-android-malware-similarity-clustering-method-using-opcode-sequence/">Dexofuzzy - Android Malware Similarity Clustering Method using opcode sequence</a></li>
                    <li><a href="https://docs.google.com/presentation/d/1sFGAERaNRuEORaH06MmZKeFRqpJo1ol1xFieUa1X_OA/edit#slide=id.p1">Chasing the Joker</a></li>
                </ul>
            </div>
            <br></br>
            <div>
                <h3>Android Pentesting Labs</h3>
                <br></br>
                <ul>
                    <li><a href="https://github.com/payatu/diva-android">DIVA (Damn insecure and vulnerable App)</a></li>
                    <li><a href="https://github.com/OWASP/SecurityShepherd">SecurityShepherd</a></li>
                    <li><a href="https://github.com/logicalhacking/DVHMA">Damn Vulnerable Hybrid Mobile App (DVHMA)</a></li>
                    <li><a href="https://github.com/OWASP/owasp-mstg/tree/master/Crackmes">OWASP MSTG</a></li>
                    <li><a href="https://github.com/dan7800/VulnerableAndroidAppOracle">VulnerableAndroidAppOracle</a></li>
                    <li><a href="https://github.com/dineshshetty/Android-InsecureBankv2">Android-InsecureBankv2</a></li>
                    <li><a href="https://github.com/htbridge/pivaa">Purposefully Insecure and Vulnerable Android Application (PIIVA)</a></li>
                </ul>
            </div>
            <br></br>
            <div>
                <h2>IOS Pentesting</h2>
                <br></br>
                <ul>
                    <li><a href="https://www.cse.wustl.edu/~jain/cse571-14/ftp/ios_security/index.html">IOS Security</a></li>
                    <li><a href="https://github.com/ashishb/osx-and-ios-security-awesome">OSX and IOS Security Awesome</a></li>
                    <li><a href="https://manuals.info.apple.com/MANUALS/1000/MA1902/en_US/apple-platform-security-guide.pdf">Apple Platform Security</a></li>
                    <li><a href="https://resources.infosecinstitute.com/topic/ios-application-security-part-1-setting-up-a-mobile-pentesting-platform/#gref">IOS Application Security</a></li>
                    <li><a href="https://github.com/felixgr/secure-ios-app-dev">Most Common Vulnerabilities in IOS Applications</a></li>
                    <li><a href="https://www.owasp.org/index.php/IOS_Application_Security_Testing_Cheat_Sheet">IOS Pentesting Cheatsheet</a></li>
                    <li><a href="https://mobile-security.gitbook.io/mobile-security-testing-guide/ios-testing-guide/0x06b-basic-security-testing">Owasp IOS Basic Security Testing</a></li>
                    <li><a href="https://medium.com/@ansjdnakjdnajkd/dynamic-analysis-of-ios-apps-wo-jailbreak-1481ab3020d8">Dynamic analysis of iOS apps w/o Jailbreak</a></li>
                    <li><a href="https://arjunbrar.com/post/ios-application-injection">IOS Application Injection</a></li>
                    <li><a href="https://spaceraccoon.dev/low-hanging-apples-hunting-credentials-and-secrets-in-ios-apps">Low-Hanging Apples: Hunting Credentials and Secrets in iOS Apps</a></li>
                    <li><a href="https://blog.elcomsoft.com/2019/12/bfu-extraction-forensic-analysis-of-locked-and-disabled-iphones/">BFU Extraction: Forensic Analysis of Locked and Disabled iPhones</a></li>
                    <li><a href="https://blog.elcomsoft.com/2019/10/how-to-extract-screen-time-passcodes-and-voice-memos-from-icloud/">How to Extract Screen Time Passcodes and Voice Memos from iCloud</a></li>
                    <li><a href="https://github.com/iOS-Reverse-Engineering-Dev/Swift-Apps-Reverse-Engineering/blob/master/Reverse%20Engineering%20Swift%20Applications.pdf">Reverse Engineering Swift Apps</a></li>
                    <li><a href="https://sensepost.com/blog/2019/mettle-your-ios-with-frida/">Mettle your IOS with Frida</a></li>
                    <li><a href="https://blog.securelayer7.net/a-run-time-approach-for-pen-testing-ios-applications-part-ii-objection-in-action/">A run-time approach for pentesting iOS applications</a></li>
                    <li><a href="https://blog.umangis.me/a-deep-dive-into-ios-code-signing/">A Deep Dive into iOS Code Signing</a></li>
                    <li><a href="https://kishanbagaria.com/airdos/">AirDoS: Remotely render any nearby iPhone or iPad unusable</a></li>
                    <li><a href="https://github.com/ansjdnakjdnajkd/iOS">Most usable tools for iOS penetration testing</a></li>
                    <li><a href="https://github.com/0xmachos/iOS-Security-Guides">IOS Security Guides</a></li>
                    <li><a href="https://i.blackhat.com/eu-19/Thursday/eu-19-Yen-Trust-In-Apples-Secret-Garden-Exploring-Reversing-Apples-Continuity-Protocol-3.pdf">Trust in Apple's Secret Garden: Exploring & Reversing Apple's Continuity Protocol-Slides</a></li>
                </ul>
            </div>
            <br></br>
            <div>
                <h3>IOS Pentesting Labs</h3>
                <br></br>
                <ul>
                    <li><a href="https://owasp.org/www-project-igoat-tool/">Owasp Igoat</a></li>
                    <li><a href="https://github.com/prateek147/DVIA">Damn Vulnerable IOS App v1</a></li>
                    <li><a href="https://github.com/prateek147/DVIA-v2">Damn Vulnerable IOS App v2</a></li>
                    <li><a href="https://github.com/SecurityCompass/iPhoneLabs">IPhone Labs</a></li>
                </ul>
            </div>
        </div>
    )
}

export default mobilePentesting
