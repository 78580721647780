import React from 'react';

const Home = () => {
  return (
    <>
      <div className="Home">
        <h1 className="Home-Header">HACKERSTOP</h1>
        <p>An Extensive Repository for Free Resources on <h4 style={{ marginTop: '4px' }}></h4></p>
        <h6 className="Upcoming">Upcoming: Reverse Engineering, Forensics, ejpt certification and much more resources!</h6>
      </div>
    </>
  );
};

export default Home;
